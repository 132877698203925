import { configureStore } from "@reduxjs/toolkit";

import rootReducer from "./index";


export default function createStore(){
    return configureStore({
        reducer: rootReducer,
        middleware: (getDefaultMiddleware) => getDefaultMiddleware()
    })
}
